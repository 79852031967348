<template>
  <div id="base-plan" class="mb-10">
    <div>
      <b-card no-body>
        <b-card-text>
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 class="mt-4">Oven report</h5>
              <hr />
              <div class="row mt-4">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Shift</label>
                    <input
                      type="text"
                      v-model="shift"
                      class="form-control"
                      :class="[errors['shift'] ? 'border border-danger' : '']"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Date</label>
                    <date-picker
                      :date-format="'YYYY-MM-DD'"
                      :shortcuts="dateoptions"
                      valueType="format"
                      v-model="date"
                      :class="[errors['date'] ? 'border border-danger' : '']"
                    ></date-picker>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Oven Started On</label>
                    <input
                      type="time"
                      v-model="oven_started_on"
                      class="form-control"
                      :class="[
                        errors['oven_started_on'] ? 'border border-danger' : '',
                      ]"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Oven Supervisor</label>
                    <select
                      class="form-control"
                      :class="[
                        errors['oven_supervise'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="oven_supervise"
                    >
                      <option value="">Select Oven Supervisor</option>
                      <option
                        v-for="(item, index) in filteredOvenSupervisorUsers"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Shift Incharge</label>
                    <select
                      class="form-control"
                      :class="[
                        errors['shift_inchrge'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="shift_inchrge"
                    >
                      <option value="">Select Shift Incharge</option>
                      <option
                        v-for="(item, index) in filteredShiftInchargeUsers"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 style="text-align: center">{{ plant_A }}</h5>
              <hr />
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%">Variety</th>
                    <th scope="col">Started On</th>
                    <th scope="col">Finished At</th>
                    <th scope="col">Break From</th>
                    <th scope="col">Break To</th>

                    <th scope="col" style="width: 15%">Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in plant_A_details"
                    :key="index"
                    :class="[item.error == 1 ? 'row_red' : '']"
                  >
                    <td class="ino" style="width: 20%">
                      <input
                        type="text"
                        v-model="item.variety"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.started_on"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.finished_at"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.break_from"
                        class="form-control"
                      />
                    </td>

                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.break_to"
                        class="form-control"
                      />
                    </td>
                    <td class="ino" style="width: 15%">
                      <input
                        type="text"
                        v-model="item.remarks"
                        class="form-control"
                      />
                    </td>
                    <td class="action">
                      <button
                        v-if="index > 0"
                        class="btn btn-danger btn-sm mr-3"
                        @click="removeplanA(index)"
                      >
                        Delete
                      </button>
                      <button
                        v-if="index == plant_A_details.length - 1"
                        class="btn btn-success btn-sm mr-3"
                        @click="planpushA()"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 style="text-align: center">{{ plant_B }}</h5>
              <hr />
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" style="width: 20%">Variety</th>
                    <th scope="col">Started On</th>
                    <th scope="col">Finished At</th>
                    <th scope="col">Break From</th>
                    <th scope="col">Break To</th>

                    <th scope="col" style="width: 15%">Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in plant_B_details"
                    :key="index"
                    :class="[item.error == 1 ? 'row_red' : '']"
                  >
                    <td class="ino" style="width: 20%">
                      <input
                        type="text"
                        v-model="item.variety"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.started_on"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.finished_at"
                        class="form-control"
                      />
                    </td>
                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.break_from"
                        class="form-control"
                      />
                    </td>

                    <td class="datetime">
                      <input
                        type="time"
                        v-model="item.break_to"
                        class="form-control"
                      />
                    </td>
                    <td class="ino" style="width: 15%">
                      <input
                        type="text"
                        v-model="item.remarks"
                        class="form-control"
                      />
                    </td>
                    <td class="action">
                      <button
                        v-if="index > 0"
                        class="btn btn-danger btn-sm mr-3"
                        @click="removeplanB(index)"
                      >
                        Delete
                      </button>
                      <button
                        v-if="index == plant_B_details.length - 1"
                        class="btn btn-success btn-sm mr-3"
                        @click="planpushB()"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="btn btn-smplan-fixed">
            <button
              ref="kt_submit_base_plan"
              class="btn btn-warning mt-2 mb-2 mr-5"
              @click="goback()"
            >
              Go back
            </button>
            <button
              v-if="!isEdit"
              ref="kt_submit_base_plan"
              vi
              class="btn btn-success mt-2 mb-2 mr-3"
              @click="save()"
            >
              Save
            </button>
            <button
              v-if="isEdit"
              ref="kt_submit_base_plan"
              vi
              class="btn btn-success mt-2 mb-2 mr-3"
              @click="update()"
            >
              Update
            </button>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  STORE_DAILY_OVEN_REPORT,
  FETCH_SELF_OVEN_REPORT,
  UPDATE_DAILY_OVEN_REPORT,
  FETCH_ORGANIZATION_USER,
} from "@/core/services/store/actions.type";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(["currentUser", "getOrganizationUsers"]),
    filteredOvenSupervisorUsers() {
      let ovensupervisorusers = this.getOrganizationUsers;
      ovensupervisorusers = ovensupervisorusers.filter((item) => {
        if (this.$route.name == "client.ovenreport.edit") {
          return item.roles == "All" || item.roles == "Oven Supervisor";
        } else {
          return (
            item.is_active &&
            !item.is_deleted &&
            (item.roles == "All" || item.roles == "Oven Supervisor")
          );
        }
      });

      return ovensupervisorusers;
    },
    filteredShiftInchargeUsers() {
      let shiftinchargeusers = this.getOrganizationUsers;
      //process search input
      shiftinchargeusers = shiftinchargeusers.filter((item) => {
        // console.log(item.name);
        if (this.$route.name == "client.ovenreport.edit") {
          return item.roles == "All" || item.roles == "Shift Incharge";
        } else {
          return (
            item.is_active &&
            !item.is_deleted &&
            (item.roles == "All" || item.roles == "Shift Incharge")
          );
        }
      });
      return shiftinchargeusers;
    },
  },
  data() {
    return {
      dateoptions: [{ text: "Today", onClick: () => new Date() }],
      isEdit: false,
      plant_B: "Plant-B",
      plant_A: "Plant-A",
      plan: {
        variety: "",
        started_on: "",
        finished_at: "",
        break_from: "",
        break_to: "",
        remarks: "",
        error: 0,
      },
      id: 0,
      temp_start_time: "",
      temp_end_time: "",
      old_temp_start_time: 0,
      results: [],
      errors: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Create Oven Report",
          route: "",
        },
      ],
      plant_A_details: [],
      plant_B_details: [],
      shift: "",
      date: this.$moment(new Date()).format("YYYY-MM-DD"),
      oven_started_on: "",
      oven_supervise: "",
      shift_inchrge: "",
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    this.isEdit = this.$route.name == "client.ovenreport.edit" ? true : false;
    var id = this.$route.params.id;
    let plan = {
      variety: "",
      started_on: "",
      finished_at: "",
      break_from: "",
      break_to: "",
      remarks: "",
    };
    let planB = {
      variety: "",
      started_on: "",
      finished_at: "",
      break_from: "",
      break_to: "",
      remarks: "",
    };
    this.$store.dispatch(FETCH_ORGANIZATION_USER);
    if (this.isEdit) {
      this.breadCrumbs[1].title = "Update Oven Report";
      this.$store
        .dispatch(FETCH_SELF_OVEN_REPORT, id)
        .then((data) => {
          this.plant_A_details = data.data.over_report_detail.filter((item) => {
            return item.plant == "Plant-A";
          });
          this.plant_B_details = data.data.over_report_detail.filter((item) => {
            return item.plant == "Plant-B";
          });

          if (this.plant_A_details.length < 1) {
            this.plant_A_details.push(plan);
          }
          if (this.plant_B_details.length < 1) {
            this.plant_B_details.push(planB);
          }
          this.shift = data.data.shift;
          this.date = data.data.date;
          this.oven_supervise = data.data.oven_supervise;
          this.oven_started_on = data.data.oven_started_on;
          this.shift_inchrge = data.data.shift_inchrge;
        })
        .catch(() => {
          this.$toastr.e("detail not found!");
          this.$router.push({ name: "client.ovenreport" });
        });
    } else {
      this.planpushA(1);
      this.planpushA(2);
      this.planpushB(1);
      this.planpushB(2);
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    save() {
      const det1 = this.plant_A_details.filter((data) => {
        return (
          data.variety != "" ||
          data.started_on != "" ||
          data.finished_at != "" ||
          data.break_from != "" ||
          data.break_to != "" ||
          data.remarks != ""
        );
      });
      const det2 = this.plant_B_details.filter((data) => {
        return (
          data.variety != "" ||
          data.started_on != "" ||
          data.finished_at != "" ||
          data.break_from != "" ||
          data.break_to != "" ||
          data.remarks != ""
        );
      });

      let msg = "Please fill all the value";

      var err = 0;
      this.errors = {};
      if (this.shift == "") {
        this.errors["shift"] = "This field is required";
        err = 1;
      }
      if (this.oven_started_on == "") {
        this.errors["oven_started_on"] = "This field is required";
        err = 1;
      }
      if (this.date == "") {
        this.errors["date"] = "This field is required";
        err = 1;
      }
      if (this.shift_inchrge == "") {
        this.errors["shift_inchrge"] = "This field is required";
        err = 1;
      }
      if (this.oven_supervise == "") {
        this.errors["oven_supervise"] = "This field is required";
        err = 1;
      }
      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det1.length < 1 && det1.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }
      var data = {
        "Plant-A": det1,
        "Plant-B": det2,
      };
      var formData = {
        oven_detail: data,
        shift: this.shift,
        oven_started_on: this.oven_started_on,
        date: this.date,
        oven_supervise: this.oven_supervise,
        shift_inchrge: this.shift_inchrge,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
        .dispatch(STORE_DAILY_OVEN_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.dailyovenreports",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    update() {
      const det1 = this.plant_A_details.filter((data) => {
        return (
          data.variety != "" ||
          data.started_on != "" ||
          data.finished_at != "" ||
          data.break_from != "" ||
          data.break_to != "" ||
          data.remarks != ""
        );
      });
      const det2 = this.plant_B_details.filter((data) => {
        return (
          data.variety != "" ||
          data.started_on != "" ||
          data.finished_at != "" ||
          data.break_from != "" ||
          data.break_to != "" ||
          data.remarks != ""
        );
      });
      let msg = "Please fill all the value";

      var err = 0;
      this.errors = {};
      if (this.shift == "") {
        this.errors["shift"] = "This field is required";
        err = 1;
      }
      if (this.oven_started_on == "") {
        this.errors["oven_started_on"] = "This field is required";
        err = 1;
      }
      if (this.date == "") {
        this.errors["date"] = "This field is required";
        err = 1;
      }
      if (this.shift_inchrge == "") {
        this.errors["shift_inchrge"] = "This field is required";
        err = 1;
      }
      if (this.oven_supervise == "") {
        this.errors["oven_supervise"] = "This field is required";
        err = 1;
      }
      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      if (det1.length < 1 && det1.length < 1) {
        let msg = "Please add atleast one record";
        this.$toastr.w(msg);
        return 0;
      }
      var data = {
        "Plant-A": det1,
        "Plant-B": det2,
      };

      var formData = {
        id: this.$route.params.id,
        oven_detail: data,
        shift: this.shift,
        oven_started_on: this.oven_started_on,
        date: this.date,
        oven_supervise: this.oven_supervise,
        shift_inchrge: this.shift_inchrge,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
        .dispatch(UPDATE_DAILY_OVEN_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.dailyovenreports",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplanA(index) {
      // console.log(weekday, index);
      this.plant_A_details.splice(index, 1);
    },
    removeplanB(index) {
      // console.log(weekday, index);
      this.plant_B_details.splice(index, 1);
    },
    planpushA() {
      let plan = {
        variety: "",
        started_on: "",
        finished_at: "",
        break_from: "",
        break_to: "",
        remarks: "",
      };
      this.plant_A_details.push(plan);
      this.add_status = 1;
    },
    planpushB() {
      let plan = {
        variety: "",
        started_on: "",
        finished_at: "",
        break_from: "",
        break_to: "",
        remarks: "",
      };
      this.plant_B_details.push(plan);
      this.add_status = 1;
    },
    reset() {
      this.plan = {
        variety: "",
        started_on: "",
        finished_at: "",
        break_from: "",
        break_to: "",
        remarks: "",
      };
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.row_red {
  background-color: #f89494 !important;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
table {
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 10%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
</style>
